
import { defineComponent, ref } from 'vue'
import { useFetchData } from '@/composables'
import usePayoutsRepositories from '@/repositories/usePayoutsRepositories'
import useProfitsRepositories from '@/repositories/useProfitsRepositories'
import { ElMessage } from 'element-plus'

export default defineComponent({
  components: {},
  setup(props, { emit }) {
    const dialogVisible = ref<boolean>(false)
    const baseResource = 'payouts/config'
    const amount = ref(0)
    const method = ref('')
    const availablePayout = ref(0)
    let methods = ref([{ label: '', value: '' }])
    const MIN_PAYOUT = 50
    const { createPayoutRequest } = usePayoutsRepositories()
    const { getAvailableProfits } = useProfitsRepositories()

    const close = () => {
      methods.value = []
      amount.value = 0
      method.value = ''
      dialogVisible.value = false
    }
    const open = async () => {
      await fetchData()
      methods.value = documents.value.map((item: any) => {
        return {
          label: item.paymentType,
          value: item._id,
        }
      })
      const randomId = 'a127sd9xX1812'
      const res = (await getAvailableProfits(randomId)) as any
      availablePayout.value = res.data.remainProfit || 0
      dialogVisible.value = true
    }
    const { documents, fetchData } = useFetchData(baseResource, {})

    const request = async () => {
      if (!method.value) {
        ElMessage.error('Please select payout method')
        return
      }
      if (availablePayout.value < MIN_PAYOUT) {
        ElMessage.error('The balance is not enough to make payout request!')
        return
      }
      if (MIN_PAYOUT > availablePayout.value) {
        ElMessage.error(`The balance is not enough to make payout request!`)
        return
      }
      if (
        !amount.value ||
        amount.value < MIN_PAYOUT ||
        amount.value > availablePayout.value
      ) {
        ElMessage.error(
          `Amount must be greater than ${MIN_PAYOUT} and less than ${availablePayout.value}`
        )
        return
      }
      const res = (await createPayoutRequest(amount.value, method.value)) as any
      if (res.data.success) {
        ElMessage.success('Request payout successfully')
        emit('reset')
        close()
      } else {
        ElMessage.error(res.data.msg)
      }
    }

    return {
      close,
      open,
      request,
      amount,
      method,
      methods,
      dialogVisible,
      documents,
      availablePayout,
    }
  },
})
