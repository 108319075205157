
import { ElMessage } from 'element-plus/es/components'
import { defineComponent, ref, watch } from 'vue'
import usePayoutsRepositories from '@/repositories/usePayoutsRepositories'
import type { FormInstance } from 'element-plus'
export default defineComponent({
  components: {},
  setup() {
    const dialogVisible = ref<boolean>(false)
    const { getPayoutConfig, updatePayoutConfig, setPayoutConfig } =
      usePayoutsRepositories()

    const paymentType = ref('')
    const id = ref('')
    const creditCard = ref({
      paymentAttributes: {
        accountName: '',
        accountNumber: '',
        bankName: '',
      },
    })
    const formRef = ref<FormInstance>()

    const validateForm = ref({
      accountName: '',
      accountNumber: '',
      bankName: '',
    })
    const close = () => {
      paymentType.value = ''
      dialogVisible.value = false
    }

    const open = async () => {
      const res = (await getPayoutConfig()) as any
      paymentType.value = res.data[0]?.paymentType
      id.value = res.data[0]?._id
      creditCard.value.paymentAttributes = { ...res.data[0]?.paymentAttributes }
      dialogVisible.value = true
    }

    const submitPayoutConfig = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          if (!id.value) {
            const res = (await setPayoutConfig({
              ...creditCard.value,
              paymentType: 'creditcard',
            })) as any
            console.log(res)
            if (res?.data) {
              ElMessage.success('Set payout config successfully')
              close()
            } else {
              ElMessage.error(res.data.msg)
            }
            return
          }
          const res = (await updatePayoutConfig(id.value, {
            ...creditCard.value,
            paymentType: 'creditcard',
          })) as any
          if (res?.data) {
            ElMessage.success('Update payout config successfully')
            close()
          } else {
            ElMessage.error(res.data.msg)
          }
        }
      })
    }

    const resetForm = () => {
      if (!formRef.value) return
      formRef.value.resetFields()
    }

    watch(dialogVisible, resetForm)
    //   if (val) {
    //     const res = (await getPayoutConfig()) as any
    //     paymentType.value = res.data[0]?.paymentType
    //     id.value = res.data[0]?._id
    //     creditCard.value.paymentAttributes = {
    //       ...res.data[0]?.paymentAttributes,
    //     }
    //   }
    // })

    return {
      dialogVisible,
      creditCard,
      formRef,
      validateForm,
      close,
      open,
      submitPayoutConfig,
    }
  },
})
