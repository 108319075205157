import { reactive } from 'vue'
import axios from '@/services/axios'

export default function usePayoutsRepositories() {
  const state = reactive({})
  const baseResource = 'payouts'

  const createPayoutRequest = async (amount: number, payoutMethod: any) => {
    try {
      const resource = `${baseResource}`
      return await axios.post(resource, { amount, payoutMethod })
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const getPayoutConfig = async () => {
    try {
      const resource = `${baseResource}/config`
      return await axios.get(resource)
    } catch (error) {
      console.log(error)
      return null
    }
  }
  const setPayoutConfig = async (data: any) => {
    try {
      const resource = `${baseResource}/config`
      return await axios.post(resource, data )
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updatePayoutConfig = async (id: string,data: any) => {
    try {
      const resource = `${baseResource}/config/${id}`
      return await axios.post(resource, data )
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updatePayout = async (id: string, data: any) => {
    try {
      const resource = `${baseResource}/${id}/approve`
      return await axios.post(resource, data )
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const updatePayoutNote = async (id: string, data: any) => {
    try {
      const resource = `${baseResource}/${id}`
      return await axios.patch(resource, data )
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    state,
    baseResource,
    createPayoutRequest,
    setPayoutConfig,
    getPayoutConfig,
    updatePayoutConfig,
    updatePayout,
    updatePayoutNote
  }
}
