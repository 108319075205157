
import { computed, defineComponent, reactive, ref, onMounted, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { formatTime } from '@/utils/dateTime'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import PayoutRequestDialog from '@/pages/seller/payouts/components/RequestPayoutDialog.vue'
import useProfitsRepositories from '@/repositories/useProfitsRepositories'
import ConfigPayoutDialog from '@/pages/seller/payouts/components/ConfigPayoutDialog.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
    PayoutRequestDialog,
    ConfigPayoutDialog,
  },
  setup() {
    const breadcrumbs = ['Payouts', '']
    const title = 'Payouts'
    const baseResource = 'payouts/seller-teams'

    const { getProfitsSeller } = useProfitsRepositories()
    const router = useRouter()

    const profitData = ref({
      totalProfit: 0,
      totalHold: 0,
      totalAmountPaid: 0,
    })
    const content = computed(() => {
      return { breadcrumbs, title }
    })
    const params = reactive({
      page: 1,
      limit: 10,
    })
    const columns = [
      {
        key: '_id',
        title: 'Created Date',
        dataIndex: 'createdDate',
        minWidth: '10',
      },
      {
        title: 'Bill Name',
        dataIndex: 'billName',
        minWidth: '12',
      },
      {
        title: 'Method',
        dataIndex: 'payoutMethod',
        minWidth: '10',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        minWidth: '10',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        minWidth: '10',
      },
      {
        title: 'Approved Date',
        dataIndex: 'approvedDate',
        minWidth: '10',
      },
      {
        title: 'Note',
        dataIndex: 'note',
        minWidth: '10',
      },
    ]
    const payoneerAccount = ref('')

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    watch(params, fetchData)

    onMounted(async () => {
      await fetchData()
      const res = (await getProfitsSeller()) as any

      profitData.value = {
        totalProfit: res.data[0]?.totalProfit || 0,
        totalHold: res.data[0]?.totalHold || 0,
        totalAmountPaid: res.data[0]?.totalAmountPaid || 0,
      }
    })

    const methodName = (val: string) => {
      if (val === 'paypal') return 'PayPal'
      if (val === 'pingpong') return 'PingPong'
      if (val === 'creditcard') return 'Credit card'
      if (val === 'payoneer') return 'Payoneer'
      return '-'
    }
    const statusName = (val: any) => {
      if (val === 0) return 'Pending'
      if (val === 1) return 'Approved'
      if (val === 2) return 'Rejected'
      return 'n/a'
    }
    const payoutRequestDialog = ref<InstanceType<typeof PayoutRequestDialog>>()
    const openPayoutRequestDialog = () => {
      payoutRequestDialog.value?.open()
    }

    const configPayoutDialog = ref<InstanceType<typeof ConfigPayoutDialog>>()
    const openConfigPayoutDialog = () => {
      configPayoutDialog.value?.open()
    }

    const openProfitDetail = () => {
      router.push('/s/profit-detail')
    }

    const currency = (val: any) => {
      if (!val) return '$0.00'
      return `$${val.toFixed(2)}`
    }

    return {
      content,
      params,
      isLoading,
      documents,
      columns,
      total,
      payoutRequestDialog,
      configPayoutDialog,
      payoneerAccount,
      profitData,
      formatTime,
      methodName,
      statusName,
      openPayoutRequestDialog,
      openConfigPayoutDialog,
      openProfitDetail,
      currency,
      fetchData,
    }
  },
})
